import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"


export default () => (
  <Layout>
    <SEO title="Co je povodeň a protipovodňová opatření" />
    <h1>Co je povodeň a protipovodňová opatření</h1>
    <p><strong>Povodeň</strong> je přirozen&yacute; hydrologick&yacute; jev, kter&yacute; je důsledkem extr&eacute;mn&iacute;ch projevů počas&iacute;. Jde o př&iacute;rodn&iacute; fenom&eacute;n, kter&eacute;mu nelze zabr&aacute;nit. Vhodn&yacute;mi <strong>protipovodňov&yacute;mi opatřen&iacute;mi</strong> lze pouze snižovat riziko zaplaven&iacute; objektů a povodňov&eacute; &scaron;kody.</p>
    <p>Ochrana před povodněmi nikdy nebude absolutn&iacute;, je možn&eacute; snižovat pouze &scaron;kody, a to důslednou prevenc&iacute; a př&iacute;pravou na povodně. Je nutn&eacute; posilovat tzv. <strong>povodňovou paměť</strong>, tedy m&iacute;t povědom&iacute; o povodňov&yacute;ch ud&aacute;lostech v&nbsp;oblasti, &scaron;&iacute;řky rozlivu vody, kam až hladina sah&aacute;v&aacute; atp.</p>
    <hr /><h2>Netechnick&aacute; opatřen&iacute;</h2>
    <p>Mezi netechnick&aacute; opatřen&iacute; při ochraně proti povodn&iacute;m patř&iacute;:</p>
    <ul>
    <li><strong>ž&aacute;dn&aacute;, tj. nulov&aacute;,</strong></li>
    <li><strong>definov&aacute;n&iacute; a pr&aacute;vn&iacute; zaji&scaron;těn&iacute; z&aacute;plavov&yacute;ch &uacute;zem&iacute;,</strong></li>
    <li><strong>předpovědn&iacute; a varovn&eacute; syst&eacute;my,</strong></li>
    <li><strong>osvěta a v&yacute;chova veřejnosti.</strong></li>
    </ul>
    <h3>Ž&aacute;dn&aacute; (nulov&aacute;) opatřen&iacute;</h3>
    <p><strong><u>Ž&aacute;dn&aacute; či nulov&aacute; opatřen&iacute;</u></strong> odr&aacute;ž&iacute; zku&scaron;enost lid&iacute; žij&iacute;c&iacute;ch v&nbsp;bl&iacute;zkosti vodn&iacute;ch toků. Lid&eacute; předev&scaron;&iacute;m v&nbsp;minulosti poč&iacute;tali s&nbsp;t&iacute;m, že se voda každoročně vyl&eacute;v&aacute; a zaplavuje &uacute;doln&iacute; nivu. Proto nestavěli sv&aacute; s&iacute;dla v&nbsp;zaplavovan&yacute;ch oblastech. Toto opatřen&iacute; vych&aacute;zelo z&nbsp;tzv. povodňov&eacute; paměti, kterou je pro toto opatřen&iacute; nutn&eacute; neust&aacute;le posilovat. S&nbsp;rozvojem vodohospod&aacute;řsk&yacute;ch &uacute;prav na vodn&iacute;ch toc&iacute;ch v&scaron;ak vedlo ke sn&iacute;žen&iacute; vn&iacute;mavosti lid&iacute; k&nbsp;povodňov&eacute;mu nebezpeč&iacute; (pocit bezpeč&iacute;, rozvoj s&iacute;del a požadavek na roz&scaron;&iacute;řen&iacute; z&aacute;stavby) a nivy začaly b&yacute;t v&iacute;ce zastavov&aacute;ny.</p>
    <h3>Definice a pr&aacute;vn&iacute; zaji&scaron;těn&iacute; z&aacute;plavov&yacute;ch &uacute;zem&iacute;</h3>
    <p><strong><u>Definov&aacute;n&iacute; a pr&aacute;vn&iacute; zaji&scaron;těn&iacute; z&aacute;plavov&yacute;ch &uacute;zem&iacute;</u></strong> je dal&scaron;&iacute;m možn&yacute;m ře&scaron;en&iacute;m. Z&aacute;plavov&eacute; &uacute;zem&iacute; je administrativně určen&eacute; &uacute;zem&iacute;, kter&eacute; může b&yacute;t při v&yacute;skytu přirozen&eacute; povodně zaplaveno vodou. V&nbsp;ČR je jejich rozsah stanoven vodopr&aacute;vn&iacute;m &uacute;řadem (obec s&nbsp;roz&scaron;&iacute;řenou působnost&iacute; či kraj) na n&aacute;vrh spr&aacute;vce toku. Podle nebezpečnosti povodňov&yacute;ch průtoků je vymezov&aacute;na aktivn&iacute; z&oacute;na z&aacute;plavov&eacute;ho &uacute;zem&iacute; &ndash; &uacute;zem&iacute; se z&aacute;sadn&iacute;m omezen&iacute;m v&yacute;stavby.</p>
    <h3>Předpovědn&iacute; a varovn&eacute; syst&eacute;my</h3>
    <p><strong><u>Předpovědn&iacute; a varovn&eacute; syst&eacute;my</u></strong> jsou pak velmi důležit&yacute;m n&aacute;strojem pro omezen&iacute; &scaron;kod či ztr&aacute;t životů. Ře&scaron;&iacute; je metodick&yacute; pokyn MŽP k&nbsp;zabezpečen&iacute; hl&aacute;sn&eacute; a předpovědn&iacute; služby, kter&yacute; ře&scaron;&iacute;:</p>
    <ul>
    <li>definov&aacute;n&iacute; informačn&iacute;ch toků,</li>
    <li>definov&aacute;n&iacute; stupňů povodňov&eacute; aktivity,</li>
    <li>definov&aacute;n&iacute; hl&aacute;sn&yacute;ch profilů.</li>
    </ul>
    <h4>Předpovědn&iacute; povodňov&aacute; služba</h4>
    <p><strong>Předpovědn&iacute; povodňovou službu </strong>zaji&scaron;ťuje ČHM&Uacute; ve spolupr&aacute;ci s&nbsp;podniky povod&iacute; a ministerstvem a poskytuje povodňov&yacute;m org&aacute;nům v&yacute;stražn&eacute; informace, předpovědi o vzniku povodně, dal&scaron;&iacute;m v&yacute;voji povodně a hydrometeorologick&yacute;ch prvc&iacute;ch (sr&aacute;žky, vodn&iacute; stavy, průtoky).</p>
    <h4>Hl&aacute;sn&aacute; povodňov&aacute; služba</h4>
    <p><strong>Hl&aacute;snou povodňovou službu </strong>zabezpečuj&iacute; povodňov&eacute; org&aacute;ny a pod&iacute;lej&iacute; se na n&iacute; ostatn&iacute; &uacute;častn&iacute;ci ochrany před povodněmi. Poskytuje povodňov&yacute;m org&aacute;nům informace pro zabezpečen&iacute; jejich &uacute;kolů v průběhu povodně a je postavena na informac&iacute;ch z ter&eacute;nu (hl&aacute;sn&eacute; profily, stav a průtočnost koryt, stav hr&aacute;z&iacute;, rozlivy, aj.). Pro před&aacute;v&aacute;n&iacute; informac&iacute; se využ&iacute;v&aacute; OPIS HZS a složek Integrovan&eacute;ho z&aacute;chrann&eacute;ho syst&eacute;mu.</p>
    <h4>Stupně povodňov&eacute; aktivity</h4>
    <p><strong>Stupně povodňov&eacute; aktivity</strong> vyjadřuj&iacute; m&iacute;ru povodňov&eacute;ho nebezpeč&iacute;. Jsou stanoveny na z&aacute;kladě směrodatn&yacute;ch limitů v&nbsp;hl&aacute;sn&yacute;ch profilech vodn&iacute;ch toků, popř. jin&yacute;ch mezn&iacute;ch hodnot (denn&iacute; &uacute;hrn sr&aacute;žek, hladina vody v&nbsp;n&aacute;drž&iacute; atd.). Rozli&scaron;ujeme 3 SPA:</p>
    <ul>
    <li>I. SPA &ndash; bdělost</li>
    <li>II. SPA &ndash; pohotovost</li>
    <li>III. SPA &ndash; ohrožen&iacute; (popř. extr&eacute;mn&iacute; ohrožen&iacute;)</li>
    </ul>
    <h4>Hl&aacute;sn&eacute; profily</h4>
    <p><strong>Hl&aacute;sn&eacute; profily</strong> jsou m&iacute;sto na vodn&iacute;m toku slouž&iacute;c&iacute; ke sledov&aacute;n&iacute; průběhu povodně. Rozli&scaron;ujeme 3 kategorie:</p>
    <ul>
    <li>A &ndash; z&aacute;kladn&iacute; (maj&iacute; ho ve spr&aacute;vě ČHM&Uacute;, či podnik Povod&iacute;)</li>
    <li>B &ndash; doplňkov&yacute; (kraje)</li>
    <li>C &ndash; pomocn&eacute; (obec, či vlastn&iacute;k ohrožen&eacute; nemovitosti)</li>
    </ul>
    <h3>Osvěta a v&yacute;chova veřejnosti</h3>
    <p><strong><u>Osvěta a v&yacute;chova veřejnosti</u></strong> je kl&iacute;čov&yacute;m opatřen&iacute; pro omezen&iacute; materi&aacute;ln&iacute;ch &scaron;kod a předev&scaron;&iacute;m ztr&aacute;t&aacute;ch na životech. Důležit&aacute; je informovanost obyvatelstva o povodňov&eacute;m riziku, o možn&eacute;m předch&aacute;zen&iacute; rizika a o spr&aacute;vn&eacute;m chov&aacute;n&iacute; v době ohrožen&iacute;, dostupnost &uacute;dajů o povodňov&eacute;m riziku, varov&aacute;n&iacute; před a během povodňov&eacute; situace, zku&scaron;enosti z historick&yacute;ch povodn&iacute; (posilov&aacute;n&iacute; povodňov&eacute; paměti).</p>
    <hr /><h2>Technick&aacute; opatřen&iacute;</h2>
    <p>Technick&aacute; opatřen&iacute; a z&aacute;sahy rozdělujeme do dvou hlavn&iacute;ch skupin:</p>
    <ul>
    <li><strong>v&nbsp;plo&scaron;e povod&iacute;,</strong></li>
    <li><strong>př&iacute;mo na vodn&iacute;ch toc&iacute;ch.</strong></li>
    </ul>
    <h3>V plo&scaron;e povod&iacute;</h3>
    <p>Opatřen&iacute; <strong><u>v&nbsp;plo&scaron;e povod&iacute;</u></strong>maj&iacute; za c&iacute;l zv&yacute;&scaron;it schopnost zachytit vodu v&nbsp;povod&iacute; a zpomalit jej&iacute; odtok. Zahrnuj&iacute; tak&eacute; změny ve využ&iacute;v&aacute;n&iacute; pozemků a komplexn&iacute; pozemkov&eacute; &uacute;pravy. Patř&iacute; mezi ně např&iacute;klad <strong>regulace zemědělstv&iacute;</strong> (omezen&iacute; &scaron;irokoř&aacute;dkov&yacute;ch plodin), <strong>lesn&iacute;ho hospod&aacute;řstv&iacute;</strong> a <strong>zav&aacute;děn&iacute; retenčn&iacute;ch opatřen&iacute;</strong> v&nbsp;plo&scaron;e povod&iacute;. Konkr&eacute;tněji může j&iacute;t o zakl&aacute;d&aacute;n&iacute; vegetačn&iacute;ch p&aacute;sů, zatravňov&aacute;n&iacute;, zalesňov&aacute;n&iacute; atd.</p>
    <h3>Na vodn&iacute;m toku</h3>
    <p>Opatřen&iacute; př&iacute;mo <strong><u>na vodn&iacute;ch toc&iacute;ch</u></strong> mohou b&yacute;t různorod&aacute;, může j&iacute;t o budov&aacute;n&iacute; <strong>retenčn&iacute;ch prostor v&nbsp;&uacute;doln&iacute;ch n&aacute;drž&iacute;ch</strong>. Tyto n&aacute;drže pak maj&iacute; i dal&scaron;&iacute; četn&aacute; využit&iacute; (energetika, akumulace vody, rekreace atd.). D&aacute;le může j&iacute;t o budov&aacute;n&iacute; <strong>retenčn&iacute;ch prostorů v&nbsp;poldrech</strong>, což jsou such&eacute; n&aacute;drže, kter&eacute; při povodňov&eacute; ud&aacute;losti zachyt&iacute; přebytek vody. V&nbsp;jejich plo&scaron;e je možn&eacute; hospodařen&iacute;, při zaplaven&iacute; n&aacute;lež&iacute; pak hospod&aacute;ři n&aacute;hrada &scaron;kody. Dal&scaron;&iacute;mi možnostmi je <strong>zkapacitněn&iacute; koryt toků</strong>, kter&eacute; pak pojmou v&iacute;ce vody, či <strong>stavba ochrann&yacute;ch hr&aacute;z&iacute;</strong>. A to ať už zemn&iacute;ch či jen pomoc&iacute; mobiln&iacute;ho hrazen&iacute;.</p>
    <p>Opatřen&iacute; mus&iacute; b&yacute;t navrhov&aacute;na tak, aby nezhor&scaron;ovala průběh povodně. <strong>&Scaron;patn&yacute;m př&iacute;kladem</strong> jsou např&iacute;klad <strong>např&iacute;men&aacute;, opevněn&aacute; koryta</strong>, kter&aacute; sice odv&aacute;d&iacute; vodu z&nbsp;dan&eacute;ho m&iacute;sta rychleji, nicm&eacute;ně zvy&scaron;uj&iacute; rychlost a energii př&iacute;padn&eacute; povodňov&eacute; vlny a zhor&scaron;uj&iacute; tak průběh povodně n&iacute;že po proudu.</p>
    <hr /><h2>Pl&aacute;nov&aacute;n&iacute; a financov&aacute;n&iacute; povodňov&eacute; ochrany</h2>
    <p>Pl&aacute;nov&aacute;n&iacute; v oblasti vod je soustavn&aacute; koncepčn&iacute; činnost garantovan&aacute; st&aacute;tem, c&iacute;lem je dos&aacute;hnout dobr&eacute;ho stavu vod v ochraně před povodněmi a v oblasti z&aacute;sobov&aacute;n&iacute; pitnou vodou. V&nbsp;současnosti existuje pl&aacute;n pro hlavn&iacute; povod&iacute; ČR a 10 d&iacute;lč&iacute;ch povod&iacute;.</p>
    <p>Při financov&aacute;n&iacute; opatřen&iacute; je nejprve posuzov&aacute;na Cost &ndash; Benefit anal&yacute;za, kdy jsou porovn&aacute;ny n&aacute;klady dan&eacute;ho opatřen&iacute; a potenci&aacute;ln&iacute;ch povodňov&yacute;ch &scaron;kod. Pokud je opatřen&iacute; posouzeno jako př&iacute;nosn&eacute;, nab&iacute;z&iacute; se pro financov&aacute;n&iacute; několik zdrojů:</p>
    <ul>
    <li>dotačn&iacute; tituly ze st&aacute;tn&iacute;ho rozpočtu (MZe&ndash;Podpora prevence před povodněmi, MŽP&ndash;Operačn&iacute; program),</li>
    <li>dotačn&iacute; tituly z fondů EU,</li>
    <li>č&aacute;stečně zdroje investorů (např. firma).</li>
    </ul>
    <hr /><h2>Modelov&aacute;n&iacute; prouděn&iacute; vody v&nbsp;korytě a inundaci</h2>
    <p>Takov&aacute;to modelov&aacute;n&iacute; jsou užitečn&yacute;m n&aacute;strojem pro v&yacute;počet rozlivu a stanoven&iacute; z&aacute;plavov&yacute;ch &uacute;zem&iacute;, vyhodnocen&iacute; map povodňov&eacute;ho ohrožen&iacute; a map rizik, n&aacute;vrh a posouzen&iacute; protipovodňov&yacute;ch opatřen&iacute;, v&yacute;počty zvl&aacute;&scaron;tn&iacute;ch povodn&iacute; (protržen&iacute; vodn&iacute;ho d&iacute;la) a modelov&aacute;n&iacute; sr&aacute;žko-odtokov&yacute;ch procesů.</p>
    <p><strong>Podklady</strong> mus&iacute; b&yacute;t co nejpřesněj&scaron;&iacute;, pro modelov&aacute;n&iacute; se použ&iacute;vaj&iacute;:</p>
    <ul>
    <li><strong>geodetick&eacute;</strong> (zaměřen&iacute; koryt, objektů, digit&aacute;ln&iacute; model ter&eacute;nu),</li>
    <li><strong>hydrologick&eacute;</strong> (stanoven&iacute; N-let&yacute;ch povodn&iacute;),</li>
    <li><strong>mapov&eacute;</strong>.</li>
    </ul>
    <p>D&aacute;le se vytv&aacute;ř&iacute; <strong>matematick&yacute; model povodně</strong>, mezi nejpouž&iacute;vaněj&scaron;&iacute; patř&iacute;:</p>
    <ul>
    <li><strong>MIKE 11</strong> (komerčn&iacute;, často použ&iacute;van&yacute;),</li>
    <li><strong>HEC &ndash; RAS</strong> (americk&yacute;, volně &scaron;iřiteln&yacute;, velmi použ&iacute;van&yacute;, podč&aacute;st v&nbsp;ArcGIS, vstupem schematizovan&aacute; ř&iacute;čn&iacute; s&iacute;ť),</li>
    <li><strong>HYDROCHECK,</strong></li>
    <li><strong>AQUALOG.</strong></li>
    </ul>
    <p>Na z&aacute;kladě modelov&aacute;n&iacute; lze pak vytvořit rastrovou mapu povodňov&eacute;ho ohrožen&iacute; a rizik, kde je zn&aacute;zorněna mj. hloubka a rychlost vody v&nbsp;inundaci a kvantifikace povodňov&eacute;ho nebezpeč&iacute;. To v&scaron;e je vypočteno pro různ&eacute; intenzity povodně.</p>

    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Co je povodeň a protipovodňová opatření</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/hydrologicky-cyklus/"><button className="inv">&larr; Hydrologický cyklus</button></Link>
    <Link to="/hydrologie/podpovrchova-voda/"><button className="inv">Podpovrchová voda &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
